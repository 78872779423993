/* Adjust selectors based on your setup */
.ant-layout-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #f5f5f5; /* Or your desired color */
    z-index: 10; /* Ensure it's above other content */
  }

  .ant-layout-content {
    padding: 0px; 
    height: calc(100vh - 162px); 
  }
  .ant-layout-footer {
    position: fixed;
    height: 40px;
    bottom: 0;
    width: 100%;
    background-color: #f5f5f5;
    z-index: 10;
  }